import { formatCurrency } from '@angular/common';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { group, NonEmptyArray } from 'fp-ts/es6/NonEmptyArray';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { I18nService } from 'app/services/i18n.service';
import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { BundleActions, SubscriptionActions } from 'app/store/actions';
import { AccountType, Service, ServiceProvider, State, Tenant } from 'app/store/models';
import { BundleTemplate } from 'app/store/models/bundle.model';
import { byServiceProvider } from 'app/store/models/service.model';
import { BillingType, SubscriptionTemplate, SubscriptionTemplateTier } from 'app/store/models/subscription.model';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';
import { RechargeWalletModalComponent } from './recharge-wallet-modal.component';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
})
export class MarketplaceComponent implements OnDestroy {
  linkedServices$: Subscription;
  unlinkedServices$: Subscription;
  subProviders$: Subscription;
  insufficientFundsGuard$: Subscription;
  subscriptionTemplates: SubscriptionTemplate[] = [];
  bundleTemplates: BundleTemplate[] = [];
  bundlePriceOptions: { [k:string]: { label: string, value: string }[] } = {};
  bundlePriceModel: { [k:string]: string } = {};
  bundlePricePerToken: { [k:string]: number } = {};
  bundlePrice: { [k:string]: number } = {};
  serviceMap: { [k: string]: string } = {};
  services: Service[]|null = null;
  serviceProvider: ServiceProvider|null = null;
  billingOptionsModel: { [k: string]: string } = {};
  serviceProviderId: string|null = null;
  highlightedTierId: string|null = null;
  walletCharged: boolean|null = null;
  accountPaired: boolean|null = null;
  subscriptionStarted: boolean|null = null;
  saveFlags: { [key: string]: boolean } = {};

  @ViewChild('scrollAnchor')
  scrollAnchor?: ElementRef;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private i18nService: I18nService,
    private route: ActivatedRoute,
    private loader: LazyLoaderService,
  ) {
    this.route.paramMap.subscribe(params => {
      this.serviceProviderId = params.get('providerId');
      this.highlightedTierId = params.get('highlightedTierId');
    });

    this.linkedServices$ = this.loader.getLinkedServices().pipe(
      map(group(byServiceProvider)),
      untilDestroyed(this)
    ).subscribe(x => this.setData(x, true));

    this.unlinkedServices$ = this.loader.getUnlinkedServices().pipe(
      map(group(byServiceProvider)),
      untilDestroyed(this)
    ).subscribe(y => this.setData(y, false));

    this.subProviders$ = this.loader.getSubProviders().pipe(
      untilDestroyed(this)
    ).subscribe(subProviders => this.setDataFromSubProvider(subProviders));

    this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
      map(accs => accs.accounts.find(a => a.type === AccountType.SPENDER) || null),
      filter(notNull),
      untilDestroyed(this)
    ).subscribe(a => {
      this.walletCharged = (parseFloat(a.available) !== 0)
    });

    this.actions$.pipe(
      ofType(SubscriptionActions.loadSubscriptionTemplatesForUserSuccess),
      filter(notNull),
      untilDestroyed(this),
    ).subscribe(({ items }) => {
      let subscriptionStarted = false;
      items.forEach(item => {
        if (item.active) {
          subscriptionStarted = true;
        }
        item.subscriptionTemplateTiers?.forEach((tier: any) => {
          this.billingOptionsModel[tier.id] = tier.priceMonthly ? BillingType.MONTHLY : BillingType.YEARLY;
          if (this.highlightedTierId) {
            tier.highlighted = false;
            if (tier.id === this.highlightedTierId) {
              tier.highlighted = true;
              if (this.scrollAnchor) {
                this.scrollToAnchor(this.scrollAnchor.nativeElement);
              }
            }
          }
          this.saveFlags[tier.id] = false;
        });
      });
      this.subscriptionTemplates = items;
      this.subscriptionStarted = subscriptionStarted;
    });

    this.actions$.pipe(
      ofType(BundleActions.loadBundleTemplatesForUserSuccess),
      filter(notNull),
      untilDestroyed(this),
    ).subscribe(({ items }) => {
      items.forEach(item => {
        this.saveFlags[item.id] = false;
        this.bundlePriceOptions[item.id] = [];
        let defaultPrice = null;
        item.bundleTemplateService.bundleTemplateServicePrices.forEach(
          price => {
            this.bundlePriceOptions[item.id].push({ label: price.tokens.toString(), value: price['@id']})
            if (price.isDefault) {
              defaultPrice = price;
            }
          }
        );
        if (!defaultPrice) {
           defaultPrice = item.bundleTemplateService.bundleTemplateServicePrices[0];
        }
        if (defaultPrice) {
          this.bundlePriceModel[item.id] = defaultPrice['@id'];
          this.bundlePricePerToken[item.id] = parseFloat(defaultPrice.pricePerToken);
          this.bundlePrice[item.id] = parseFloat(defaultPrice.pricePerToken) * defaultPrice.tokens;
        }
      });
      this.bundleTemplates = items;
    });

    this.actions$.pipe(
      ofType(
        SubscriptionActions.startSubscriptionSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      Object.keys(this.saveFlags).forEach(key => this.saveFlags[key] = false);
      this.router.navigateByUrl('/services/subscriptions');
    });

    this.actions$.pipe(
      ofType(
        BundleActions.startBundleSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      Object.keys(this.saveFlags).forEach(key => this.saveFlags[key] = false);
      this.router.navigateByUrl('/services/bundles');
    });

    this.actions$.pipe(
      ofType(
        SubscriptionActions.startSubscriptionFailure,
        BundleActions.startBundleFailure,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      Object.keys(this.saveFlags).forEach(key => this.saveFlags[key] = false);
    });

    this.insufficientFundsGuard$ = this.actions$.pipe(
      ofType(
        SubscriptionActions.startSubscriptionFailure,
        BundleActions.startBundleFailure,
      ),
      untilDestroyed(this),
    ).subscribe((error) => {
      if (error.error.detail === 'billing: Tenant does not have enough balance.' || error.error.detail === 'bundleTemplateServicePriceId: Tenant does not have enough balance.') {
        const modalRef = this.modalService.open(RechargeWalletModalComponent, { size: 'lg' });
        modalRef.componentInstance.setMessage(this.translate.instant(
          error.error.detail === 'billing: Tenant does not have enough balance.'
            ? 'You have insufficient balance on your wallet to start this subscription. Please top up your wallet'
            : 'You have insufficient balance on your wallet to buy this bundle. Please top up your wallet'
        ));
        modalRef.result.then(
          () => {},
          () => {},
        );
      }
    });
  }

  setData(serviceGroupByProvider: NonEmptyArray<Service>[], paired: boolean): void {
    if (serviceGroupByProvider.length === 0) {
      return;
    }

    serviceGroupByProvider.forEach((services: Service[]) => {
      const serviceProviderIdMatch = services[0].serviceProvider['@id'] === ('/api/v1/service_providers/' + this.serviceProviderId);
      const tenantIdMatch = services[0].serviceProvider.tenant['@id'] === ('/api/v1/tenants/' + this.serviceProviderId); // keeps backwards compatibility

      if (!serviceProviderIdMatch && !tenantIdMatch) {
        return;
      }

      this.accountPaired = paired;
      this.serviceProvider = services[0].serviceProvider;
      this.services = services;
      this.services.forEach(
        x => this.serviceMap[x['@id']] = x.label,
      );

      let serviceProviderId = '';
      if (serviceProviderIdMatch) {
        serviceProviderId = this.serviceProviderId || '';
      } else {
        serviceProviderId = services[0].serviceProvider.id;
      }
      this.store.dispatch(SubscriptionActions.loadSubscriptionTemplatesForUser({ providerId: serviceProviderId }));
      this.store.dispatch(BundleActions.loadBundleTemplatesForUser({ providerId: serviceProviderId }));
    });
  }

  setDataFromSubProvider(subProvider: ServiceProvider[]): void {
    subProvider.forEach(subProvider => {
      if (subProvider.id !== this.serviceProviderId) {
        return;
      }

      this.accountPaired = subProvider.pairedViaProvider;
      this.serviceProvider = subProvider;
      this.services = subProvider.services;
      this.services.forEach(
        x => this.serviceMap[x['@id']] = x.label,
      );
      this.store.dispatch(SubscriptionActions.loadSubscriptionTemplatesForUser({ providerId: this.serviceProviderId || '' }));
      this.store.dispatch(BundleActions.loadBundleTemplatesForUser({ providerId: this.serviceProviderId || '' }));
    });
  }

  handleBundlePriceChange(priceIri: string): void
  {
    let templateId: string|null = null;;
    let templatePrice: any;
    this.bundleTemplates.forEach(item => item.bundleTemplateService.bundleTemplateServicePrices.forEach(price => {
      if (price['@id'] === priceIri) {
        templateId = item.id;
        templatePrice = price;
      }
    }));

    if (templateId === null || templatePrice === null) {
      return;
    }

    this.bundlePriceModel[templateId] = priceIri;
    this.bundlePricePerToken[templateId] = parseFloat(templatePrice.pricePerToken);
    this.bundlePrice[templateId] = parseFloat(templatePrice.pricePerToken) * templatePrice.tokens;
  }

  startSubscription(subscriptionTemplate: SubscriptionTemplate, subscriptionTemplateTier: SubscriptionTemplateTier & { id: string; '@id': string; }) {
    if (!this.accountPaired) {
      return;
    }

    const billing = this.billingOptionsModel[subscriptionTemplateTier.id];
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    const price = billing === BillingType.YEARLY ? subscriptionTemplateTier.priceYearly : subscriptionTemplateTier.priceMonthly;
    modalRef.componentInstance.title = this.translate.instant('Start') + ' ' + subscriptionTemplate.label + ' - ' + subscriptionTemplateTier.label;
    modalRef.componentInstance.message = this.translate.instant('Are you sure you want to activate <b>{{subscription}}</b>? You will be charged a {{period}} fee of {{price}}.', {
      subscription : subscriptionTemplate.label + ' - ' + subscriptionTemplateTier.label,
      period: String(this.translate.instant(billing === BillingType.YEARLY ? 'Yearly' : 'Monthly')).toLowerCase(),
      price: formatCurrency(price ? parseFloat(price) : 0, this.i18nService.language, '') + ' ' + subscriptionTemplate.serviceProvider.tenant.currency.code + '*'
    })
    modalRef.componentInstance.footnote = this.translate.instant('All prices are excl. VAT/GST');
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(
      () => {
        this.saveFlags[subscriptionTemplateTier.id] = true;
        this.store.dispatch(SubscriptionActions.startSubscription({ payload: { subscriptionTemplateTier: subscriptionTemplateTier['@id'], billing }}));
      },
      () => {},
    );
  }

  startBundle(bundleTemplate: BundleTemplate) {
    if (!this.accountPaired) {
      return;
    }

    const priceIri = this.bundlePriceModel[bundleTemplate.id];
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = this.translate.instant('Start') + ' ' + bundleTemplate.bundleTemplateService.service.label + ' bundle';
    modalRef.componentInstance.message = this.translate.instant('Are you sure you want to buy a bundle of {{tokens}} tokens for <b>{{bundle}}</b>? You will be charged {{price}}.', {
      bundle : bundleTemplate.bundleTemplateService.service.label,
      tokens: this.bundlePrice[bundleTemplate.id] / this.bundlePricePerToken[bundleTemplate.id],
      price: formatCurrency(this.bundlePrice[bundleTemplate.id], this.i18nService.language, '') + ' ' + bundleTemplate.serviceProvider.tenant.currency.code + '*'
    })
    modalRef.componentInstance.footnote = this.translate.instant('All prices are excl. VAT/GST');
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(
      () => {
        this.saveFlags[bundleTemplate.id] = true;
        this.store.dispatch(BundleActions.startBundle({ payload: { bundleTemplateServicePrice: priceIri }}));
      },
      () => {},
    );
  }

  topUp(): void {
    const modalRef = this.modalService.open(RechargeWalletModalComponent, { size: 'lg' });
    modalRef.result.then(
      () => {},
      () => {},
    );
  }

  scrollToAnchor(scrollAnchor: HTMLElement): void {
    setTimeout(() => scrollAnchor.scrollIntoView({behavior: 'smooth'}), 1);
  }

  showStepsToComplete(): boolean {
    return this.walletCharged !== null && this.accountPaired !== null && this.subscriptionStarted !== null
      && (!this.walletCharged || !this.accountPaired || (this.subscriptionTemplates.length > 0 && !this.subscriptionStarted));
  }

  sanitize(x: string) {
    return x ? this.sanitizer.bypassSecurityTrustUrl(x) : '';
  }

  ngOnDestroy() {
    this.linkedServices$.unsubscribe();
    this.unlinkedServices$.unsubscribe();
    this.subProviders$.unsubscribe();
    this.insufficientFundsGuard$.unsubscribe();
  }
}
