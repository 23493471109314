<h2>{{ 'Tenants' | translate }}</h2>
<button class="btn btn-link" (click)="newTenant()">{{ 'New Tenant' | translate }}</button>
<span> | </span>
<app-loading-button (click)="exportTenants()" [isLoading]="exportingTenants" [btnClass]="'btn btn-link'" [isDisabled]="exportingTenants">
  {{ 'Export tenants to CSV' | translate }}
</app-loading-button>
<span> | </span>
<app-loading-button (click)="exportUsers()" [isLoading]="exportingUsers" [btnClass]="'btn btn-link'" [isDisabled]="exportingUsers">
  {{ 'Export users to CSV' | translate }}
</app-loading-button>
<span> | </span>
<app-loading-button (click)="exportFundsTransfers()" [isLoading]="exportingFundsTransfers" [btnClass]="'btn btn-link'" [isDisabled]="exportingFundsTransfers">
  {{ 'Export funds transfers to CSV' | translate }}
</app-loading-button>
<span> | </span>
<button class="btn btn-link" (click)="exportMonthlyBalances()">{{ 'Export monthly balances to CSV' | translate }}</button>
<span> | </span>
<button class="btn btn-link" (click)="reimburseTenant()">{{ 'Reimburse tenant' | translate }}</button>

<div class="input-group">
  <div class="input-group-text"><i class="far fa-search"></i></div>
  <input
    class="form-control"
    [placeholder]="'Name or VAT number'"
    [ngModel]="searchQuery"
    (ngModelChange)="searchQueryDebounced$.next($event)"
  />
</div>

<table class="table">
  <tr class="no-border">
    <th>Name</th>
    <th>Email</th>
    <th>VAT</th>
    <th>Phone</th>
    <th>Country</th>
    <th></th>
  </tr>
  <tr *ngFor="let tenant of tenants$ | async | sortOn: 'name'">
    <td>{{ tenant.name }}</td>
    <td class="truncate">{{ tenant.email }}</td>
    <td>{{ tenant.vatNumber }}</td>
    <td>{{ tenant.phoneNumber }}</td>
    <td><span class="fi fi-{{tenant.country.code | lowercase}}"></span></td>
    <td>
      <button class="btn btn-sm btn-link" (click)="editTenant(tenant)">
        {{ 'Edit Tenant' | translate }}
      </button>
      <button
        *ngIf="!isCinvioTenant(tenant) && !isServiceProvider(tenant) && !isLegalEntity(tenant)"
        class="btn btn-sm btn-link" (click)="deleteTenant(tenant)"
      >
        {{ 'Delete Tenant' | translate }}
      </button>
    </td>
  </tr>
</table>

<app-pagination [totalPages]="pages$ | async" [pageSource]="page$"></app-pagination>

<ng-template #tenantModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeTenant" class="modal-title">{{ 'Add new Tenant' | translate }}</h4>
    <h4 *ngIf="activeTenant" class="modal-title">{{ 'Edit Tenant' | translate }} {{ activeTenant.name }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="activeTenant ? onEditTenantSubmit() : onCreateTenantSubmit()"
      [formGroup]="tenantForm"
      class="p-3"
    >
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Company Name' | translate }}</label>
        <input class="form-control" formControlName="name">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ companyIdentifierLabel() | translate }}</label>
        <input class="form-control" formControlName="vatNumber">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Currency' | translate }}</label>
        <ng-select
          formControlName="currency"
          [items]="currencyOptions$ | async"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
        ></ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Address' | translate }}</label>
        <input class="form-control" formControlName="streetAndNumber">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Zipcode' | translate }}</label>
        <input class="form-control" formControlName="zipcode">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'City' | translate }}</label>
        <input class="form-control" formControlName="city">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Phone Number' | translate }}</label>
        <input class="form-control" formControlName="phoneNumber">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'E-mail' | translate }}</label>
        <input class="form-control" formControlName="email">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Invoice E-mail' | translate }}</label>
        <input class="form-control" formControlName="invoiceEmail">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Additional Invoice E-mails' | translate }}</label>
        <ng-select
          id="eoAdditionalInvoiceEmails"
          [items]="activeTenant?.eoAdditionalInvoiceEmails || []"
          [addTag]="true"
          [multiple]="true"
          [selectOnTab]="true"
          [isOpen]="false"
          (blur)="onAdditionalEmailsClose($event)"
          data-cy="eoAdditionalInvoiceEmails"
          formControlName="eoAdditionalInvoiceEmails"
        >
        </ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Purchase order E-mail' | translate }}</label>
        <input class="form-control" formControlName="purchaseOrderEmail">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Additional Purchase order E-mails' | translate }}</label>
        <ng-select
          id="additionalPurchaseOrderEmails"
          [items]="activeTenant?.additionalPurchaseOrderEmails || []"
          [addTag]="true"
          [multiple]="true"
          [selectOnTab]="true"
          [isOpen]="false"
          (blur)="onAdditionalPurchaseOrderEmailsClose($event)"
          data-cy="additionalPurchaseOrderEmails"
          formControlName="additionalPurchaseOrderEmails"
        >
        </ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Country' | translate }}</label>
        <ng-select
          formControlName="country"
          [items]="countries$ | async"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          (change)="onCountryChange()"
        >
          <ng-template ng-label-tmp let-item="item">
            <span class="fi fi-{{item.value | lowercase}}"></span>
            {{item.label}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span class="fi fi-{{item.value | lowercase}}"></span>
            {{item.label}}
          </ng-template>
        </ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Bank account number' | translate }}</label>
        <input class="form-control" formControlName="bankAccountNumber" pattern="[^\s]*">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'BIC' | translate }}</label>
        <input class="form-control" formControlName="bic" pattern="[^\s]*">
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingTenant$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>

<ng-template #monthlyBalancesExportModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Export monthly balances to CSV' | translate }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="onMonthlyBalancesExportSubmit()"
      [formGroup]="monthlyBalancesExportForm"
    >
      <div class="mb-3 datepicker-only-month-select">
        <label class="form-label center-block" for="until">{{ 'Until (including)' | translate }}</label>
        <div class="input-group">
          <ngb-datepicker
            formControlName="until"
            [minDate]="monthlyBalancesExportMinDate"
            [maxDate]="monthlyBalancesExportMaxDate"
            [weekdays]="false"
            [navigation]="'arrows'"
            (navigate)="exportMonthlyBalancesDateChange($event)"
          ></ngb-datepicker>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="exportingMonthlyBalances">
        {{ 'Export' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>

<ng-template #reimbursementModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Reimburse tenant' | translate }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="onReimbursementFormSubmit()"
      [formGroup]="reimbursementForm"
    >
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Tenant' | translate }}</label>
        <ng-select
          formControlName="tenant"
          [items]="reimbursementTenants$ | async"
          bindLabel="name"
        ></ng-select>
      </div>
      <app-loading-button btnType="submit" [isLoading]="reimbursingTenant">
        {{ 'Reimburse' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
