import { formatCurrency } from '@angular/common';
import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbDateStruct, NgbDatepickerNavigateEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fileSaver from 'file-saver-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { I18nService } from 'app/services/i18n.service';
import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { ToastService } from 'app/services/toast.service';
import { ValidationService } from 'app/services/validation.service';
import { TenantActions } from 'app/store/actions';
import { AccountType, Country, State, Tenant } from 'app/store/models';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';
import { CompanyIdentifierType } from 'app/store/models/country.model';

@Component({
  selector: 'app-admin-tenant',
  templateUrl: './admin-tenant.component.html',
  styleUrls: ['./admin-tenant.component.scss'],
})
export class AdminTenantComponent implements OnDestroy {
  loadingTenants$: Observable<boolean>;
  savingTenant$: Observable<boolean>;
  tenants$: Observable<Tenant[]>;
  reimbursementTenants$: Observable<Tenant[]>;
  currencyOptions$: Observable<{ value: string; label: string }[]>;
  currencyMap: { [k: string]: string } = {};
  countries$: Observable<object[]>;
  countryMap: { [k: string]: Country } = {};
  activeTenant: Tenant | null = null;
  tenantForm: UntypedFormGroup;
  monthlyBalancesExportForm: UntypedFormGroup;
  monthlyBalancesExportMinDate: NgbDateStruct = { year: 2020, month: 1, day: 1 };
  monthlyBalancesExportMaxDate: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() ?? 12,
    day: 1
  };
  reimbursementForm: UntypedFormGroup;
  modalInstance: any;
  exportingTenants = false;
  exportingUsers = false;
  exportingFundsTransfers = false;
  exportingMonthlyBalances = false;
  reimbursingTenant = false;

  page$ = new BehaviorSubject<number>(1);
  pages$: Observable<number>;
  pageSize = 20;
  searchQuery?: string;
  searchQueryDebounced$ = new BehaviorSubject<string|undefined>(undefined);

  @ViewChild('editServiceProviderModal', { static: true })
  editServiceProviderModal?: TemplateRef<any>;
  @ViewChild('tenantModal', { static: true })
  tenantModal?: TemplateRef<any>;
  @ViewChild('monthlyBalancesExportModal', { static: true })
  monthlyBalancesExportModal?: TemplateRef<any>;
  @ViewChild('reimbursementModal', { static: true })
  reimbursementModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private loader: LazyLoaderService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private actions$: Actions,
    private validationService: ValidationService,
    private api: ApiService,
    private translate: I18nService,
    private toastr: ToastService,
  ) {
    this.loadingTenants$ = this.store.select(x => x.admin.tenant.loadingItems);
    this.savingTenant$ = this.store.select(x => x.admin.tenant.savingItem);
    this.tenants$ = this.store.select(x => x.admin.tenant.paginatedItems).pipe(
      filter(notNull),
      startWith([]),
    );
    this.reimbursementTenants$ = this.store.select(x => x.admin.tenant.reimbursementItems).pipe(
      filter(notNull),
      startWith([]),
      map(y => y.filter(z => z.name !== 'cinvio')), // filter out the cinvio tenant (just in case)
    );
    this.currencyOptions$ = this.loader.getCurrencies().pipe(
      tap(currencies => currencies.forEach(x => this.currencyMap[x.code] = x['@id'])),
      map(currencies => currencies.map(currency => ({ label: currency.code, value: currency.code }))),
    );
    this.countries$ = this.loader.getCountries().pipe(
      tap(counries => { counries.forEach(x => this.countryMap[x.value] = x); this.onCountryChange(); }),
      map(xs => xs.map(x => ({ label: x.value, value: x.value }))),
    );
    this.tenantForm = this.fb.group({
      name: [null, Validators.required],
      vatNumber: [null, [Validators.required]],
      currency: [null, Validators.required],
      streetAndNumber: [null, Validators.required],
      zipcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      email: [null, Validators.required],
      invoiceEmail: [null, Validators.required],
      eoAdditionalInvoiceEmails: [null, this.validationService.emailsValidator()],
      bankAccountNumber: [null],
      bic: [null],
      purchaseOrderEmail: [null, Validators.email],
      additionalPurchaseOrderEmails: [null, this.validationService.emailsValidator()],
    });
    this.monthlyBalancesExportForm = this.fb.group({
      until: [null],
    });
    this.reimbursementForm = this.fb.group({
      tenant: [null, Validators.required],
    });
    this.actions$.pipe(
      ofType(
        TenantActions.updateTenantSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.modalInstance.dismiss();
    });
    this.actions$.pipe(
      ofType(
        TenantActions.createTenantSuccess,
        TenantActions.deleteTenantSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      if (this.modalInstance) {
        this.modalInstance.dismiss();
      }
      this.page$.next(this.page$.value);
    });

    this.pages$ = this.store.select(x => x.admin.tenant).pipe(map(x => {
      return Math.ceil((x.totalItems || 0) / this.pageSize);
    }));
    this.page$.subscribe(page => {
      this.store.dispatch(TenantActions.loadPaginatedTenants({
        page,
        pageSize: this.pageSize,
        query: this.searchQueryDebounced$.getValue(),
      }));
    });
    this.searchQueryDebounced$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe(() => this.page$.next(1));
    this.store.dispatch(TenantActions.loadReimbursementTenants());
  }

  newTenant() {
    this.activeTenant = null;
    this.tenantForm.reset();
    this.tenantForm.controls['name'].enable();
    this.tenantForm.patchValue({
      country: 'BE',
    });
    this.modalInstance = this.modalService.open(this.tenantModal, { size: 'lg' });
  }

  editTenant(tenant: Tenant) {
    this.activeTenant = tenant;
    this.tenantForm.reset();
    this.tenantForm.patchValue({
      ...this.activeTenant,
      currency: this.activeTenant.currency.code,
      country: this.activeTenant.country.code
    });
    this.onCountryChange();
    this.tenantForm.controls['name'].enable();
    if (this.isCinvioTenant(tenant)) {
      this.tenantForm.controls['name'].disable();
    }
    this.modalInstance = this.modalService.open(this.tenantModal, { size: 'lg' });
  }

  deleteTenant(t: Tenant) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker('Delete Tenant') + ' ' + t.name;
    modalRef.componentInstance.message = marker('Do you really want to delete Tenant') + ' ' + t.name + '?';
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(() => this.store.dispatch(TenantActions.deleteTenant({ tenantId: t.id })));
  }

  onCreateTenantSubmit() {
    if (this.tenantForm.invalid) {
      this.tenantForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(TenantActions.createTenant({
      tenant: {
        ...this.tenantForm.value,
        currency: this.currencyMap[this.tenantForm.value.currency],
        country: this.countryMap[this.tenantForm.value.country]['@id'],
        bankAccountNumber: this.tenantForm.value.bankAccountNumber || null,
        bic: this.tenantForm.value.bic || null,
      },
    }));
  }

  onEditTenantSubmit() {
    if (this.tenantForm.invalid || this.activeTenant === null) {
      this.tenantForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(TenantActions.updateTenant({
      tenant: {
        ...this.tenantForm.value,
        id: this.activeTenant.id,
        currency: this.currencyMap[this.tenantForm.value.currency],
        country: this.countryMap[this.tenantForm.value.country]['@id'],
        bankAccountNumber: this.tenantForm.value.bankAccountNumber || null,
        bic: this.tenantForm.value.bic || null,
      },
    }));
  }

  isCinvioTenant(tenant: Tenant | null): boolean {
    return tenant !== null && tenant.name === 'cinvio';
  }

  isLegalEntity(tenant: Tenant | null): boolean {
    return tenant !== null && !!tenant.isLegalEntity;
  }

  isServiceProvider(tenant: Tenant | null): boolean {
    return tenant !== null && !!tenant.isServiceProvider;
  }

  exportTenants() {
    if (this.exportingTenants) {
      return;
    }
    this.exportingTenants = true;
    this.api.getBlob({
      path: `/export/tenants-report/csv`,
    }).toPromise().then(
      blob => {
        this.exportingTenants = false;
        fileSaver.saveAs(blob, `tenants.csv`);
      },
      () => {
        this.exportingTenants = false;
      },
    );
  }

  exportUsers() {
    if (this.exportingUsers) {
      return;
    }
    this.exportingUsers = true;
    this.api.getBlob({
      path: `/export/users-report/csv`,
    }).toPromise().then(
      blob => {
        this.exportingUsers = false;
        fileSaver.saveAs(blob, `users.csv`);
      },
      () => {
        this.exportingUsers = false;
      },
    );
  }

  exportFundsTransfers() {
    if (this.exportingFundsTransfers) {
      return;
    }
    this.exportingFundsTransfers = true;
    this.api.getBlob({
      path: `/export/funds-transfers-report/csv`,
    }).toPromise().then(
      blob => {
        this.exportingFundsTransfers = false;
        fileSaver.saveAs(blob, `funds-transfers.csv`);
      },
      () => {
        this.exportingFundsTransfers = false;
      },
    );
  }

  exportMonthlyBalances() {
    this.modalInstance = this.modalService.open(this.monthlyBalancesExportModal, { size: 'md' });
  }

  exportMonthlyBalancesDateChange(event: NgbDatepickerNavigateEvent) {
    this.monthlyBalancesExportForm.controls['until'].setValue(event.next.year + '-' + String(event.next.month).padStart(2, '0'));
  }

  onMonthlyBalancesExportSubmit() {
    if (this.monthlyBalancesExportForm.invalid) {
      this.monthlyBalancesExportForm.markAllAsTouched();
      return;
    }

    if (this.exportingMonthlyBalances) {
      return;
    }

    this.exportingMonthlyBalances = true;
    this.api.getBlob({
      path: `/export/monthly-balances-report/csv?until=${this.monthlyBalancesExportForm.value['until']}`,
    }).toPromise().then(
      blob => {
        this.exportingMonthlyBalances = false;
        fileSaver.saveAs(blob, `monthly-balances.csv`);
      },
      () => {
        this.exportingMonthlyBalances = false;
      },
    );
  }

  reimburseTenant() {
    this.modalInstance = this.modalService.open(this.reimbursementModal, { size: 'md' });
  }

  onReimbursementFormSubmit() {
    if (this.reimbursementForm.invalid) {
      this.reimbursementForm.markAllAsTouched();
      return;
    }

    const spenderAccount = this.reimbursementForm.value['tenant'].accounts.find((a: any) => a.type === AccountType.SPENDER)
    if (!spenderAccount) {
      return;
    }

    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker('Reimburse' + ' ' + this.reimbursementForm.value['tenant'].name) + '?';
    modalRef.componentInstance.message = this.translate.translateService.instant('Are you sure you want to reimburse <b>{{price}}</b> for <b>{{tenant}}</b>? This user company\'s balance will get set to 0 and a payment request will get sent to the cinvio finance department.', {
      tenant : this.reimbursementForm.value['tenant'].name,
      price: formatCurrency(parseFloat(spenderAccount.available), this.translate.language, '') + ' ' + this.reimbursementForm.value['tenant'].currency.code
    })
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(
      () => {
        this.reimbursingTenant = true;
        this.api.post({
          path: '/reimburse/' + this.reimbursementForm.value['tenant'].id,
          body: {},
        }).toPromise().then(
          () => {
            this.reimbursingTenant = false;
            this.toastr.showSuccess({ title: 'Reimbursement successful!' });
            this.reimbursementForm.reset();
            this.store.dispatch(TenantActions.loadReimbursementTenants());
          },
          (error) => {
            this.reimbursingTenant = false;
          },
        );
      },
      () => {}
    );
  }

  onCountryChange(): void {
    if (!Object.values(this.countryMap).length) {
      return;
    }

    const companyIdentifierType = this.countryMap[this.tenantForm.value.country]?.companyIdentifierType || CompanyIdentifierType.COMPANY_IDENTIFIER_VAT

    if (companyIdentifierType === CompanyIdentifierType.COMPANY_IDENTIFIER_VAT) {
      this.tenantForm.get('vatNumber')?.setValidators([Validators.required, this.validationService.vatNumberValidator()]);
    } else {
      this.tenantForm.get('vatNumber')?.setValidators([Validators.required]);
    }

    this.tenantForm.get('vatNumber')?.updateValueAndValidity();
  }

  onAdditionalEmailsClose(event: any) {
    const currentValue = event.target.value;
    if (currentValue && !this.tenantForm.value.eoAdditionalInvoiceEmails?.includes(currentValue)) {
      this.tenantForm.patchValue({
        eoAdditionalInvoiceEmails: [...(this.tenantForm.value.eoAdditionalInvoiceEmails || []), currentValue]
      });
    }
    event.target.value = '';
  }

  onAdditionalPurchaseOrderEmailsClose(event: any) {
    const currentValue = event.target.value;
    if (currentValue && !this.tenantForm.value.additionalPurchaseOrderEmails?.includes(currentValue)) {
      this.tenantForm.patchValue({
        additionalPurchaseOrderEmails: [...(this.tenantForm.value.additionalPurchaseOrderEmails || []), currentValue]
      });
    }
    event.target.value = '';
  }

  companyIdentifierLabel(): string {
    const companyIdentifierType = this.countryMap[this.tenantForm.value.country]?.companyIdentifierType || CompanyIdentifierType.COMPANY_IDENTIFIER_VAT

    return this.translate.companyIdentifierLabel(companyIdentifierType);
  }

  ngOnDestroy() { }
}
